import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Spacer,
  TwoColumnLayout,
  UIHeader,
} from "ui-start";
import { reducer } from "../utilities/getSingleItemArray";
import { ImageWrapper } from "./styles";

export const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      allGoogleSheetServicesRow {
        edges {
          node {
            servicessectioncopy01
            servicessectioncopy02
            servicessectioncopy03
            servicessectiontitle01
            servicessectiontitle02
            servicessectiontitle03
            title
          }
        }
      }
    }
  `);
  const [title, setTitle] = useState("");
  const [servicesSectionHeader01, setServicesSectionHeader01] = useState("");
  const [section01Copy, setSection01Copy] = useState([]);
  const [section02Copy, setSection02Copy] = useState([]);
  const [section03Copy, setSection03Copy] = useState([]);
  const [servicesSectionHeader02, setServicesSectionHeader02] = useState("");
  const [servicesSectionHeader03, setServicesSectionHeader03] = useState("");
  const [imageArray] = useState([]);
  useEffect(() => {
    const dataArray = data?.allGoogleSheetServicesRow?.edges;
    setTitle(dataArray[0]["node"]["title"]);
    setServicesSectionHeader01(dataArray[0]["node"]["servicessectiontitle01"]);
    setSection01Copy(reducer(dataArray, "servicessectioncopy01"));
    setSection02Copy(reducer(dataArray, "servicessectioncopy02"));
    setSection03Copy(reducer(dataArray, "servicessectioncopy03"));
    setServicesSectionHeader02(dataArray[0]["node"]["servicessectiontitle02"]);
    setServicesSectionHeader03(dataArray[0]["node"]["servicessectiontitle03"]);
  }, [data]);

  const pageContent = (
    <>
      <Grid columns={4} mobileColumns={1} wrap>
        {imageArray.map((image) => (
          <Container>
            <ImageWrapper src={image} imgWidth="200px" mobWidth="100%" />
          </Container>
        ))}
      </Grid>
      <Grid columns={3} mobileColumns={1} wrap>
        <Container>
          <UIHeader fontSize={24} level={3} fontWeight={900} letterSpacing={2}>
            {servicesSectionHeader01}
          </UIHeader>
          {section01Copy.map((elem) => (
            <UIHeader fontSize={14} level={5}>
              {elem}
            </UIHeader>
          ))}
        </Container>
        <Container>
          <UIHeader fontSize={24} level={3} fontWeight={900} letterSpacing={2}>
            {servicesSectionHeader02}
          </UIHeader>
          {section02Copy.map((elem) => (
            <UIHeader fontSize={14} level={5}>
              {elem}
            </UIHeader>
          ))}
        </Container>
        <Container>
          <UIHeader fontSize={24} level={3} fontWeight={900} letterSpacing={2}>
            {servicesSectionHeader03}
          </UIHeader>
          {section03Copy.map((elem) => (
            <UIHeader fontSize={14} level={5}>
              {elem}
            </UIHeader>
          ))}
        </Container>
      </Grid>
    </>
  );

  return (
    <>
      <Spacer height={24} />
      <TwoColumnLayout
        leftFlexBasis={20}
        rightFlexBasis={80}
        title={title}
        headerFontSize={30}
        mobileHeaderFontSize={35}
        titleLetterSpacing={2}
      >
        <Spacer height={6} />
        {pageContent}
      </TwoColumnLayout>
    </>
  );
};
