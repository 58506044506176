import styled from "styled-components";

const colors = {
  primary: "#330000",
};

export const StyledImageCaption = styled.p`
  font-size: 11px;
  color: #999;
`;

export const UnderlinedHeader = styled.p`
  font-size: 22px;
  font-weight: bold;
`;

export const BoldHeader = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: ${colors.primary};
`;

export const CenteredContent = styled.div`
  text-align: center;
`;

export const StyledImageWrapper = styled.img`
  ${({ width }) => width && `width: ${width};`}
  ${({ border }) => border && `border: solid 1px;`}
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;
