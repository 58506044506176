import React from 'react'
import { PageHeader } from './pageHeader'
import { BoldHeader, StyledImageWrapper } from './shared';
import { Spacer } from './styles';

export const Events = () => {
    return (
      <div>
        <PageHeader title="Event Production" />
        <p>
          Adams&Neill offer unique solutions to the creative needs of brands. This includes concept development, event
          PR, sourcing celebrities for red carpet appearances, venue scouting, art direction, live performances, DJ's
          and talent booking, set building, photography and videography.
        </p>
        <p>
          We are also experienced in local production and location scouting in Switzerland and Spain for advertising,
          photography, television and film production needs, for clients such as fashion brands Hackett and Wrangler.
        </p>
        <BoldHeader>Case Studies:</BoldHeader>
        <Spacer size={16} />
        <StyledImageWrapper
          src="http://www.gartmann.biz/fileadmin/_processed_/d/a/csm_141012_SnowPolo_68d8918d5e.jpg"
          width="200px"
          border
        />
        <Spacer size={16} />
        <StyledImageWrapper src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-events.png" width="80%" />
        <p>
          Adams & Neill created a global marketing strategy and PR campaign for Klosters Snow Polo, an annual polo
          tournament in the snow set in the moutain resort of Klosters. Alongside the PR and marketing aspect
          Adams&Neill created an intimate VIP evening dinner working alongside the Sentebale charity founded by HRH
          Prince Harry and Prince Seeiso of Lesotho. We sourced celebrities on the red carpet, such as Annie Lennox and
          Duran Duran, co-ordinated sponsorship dontations, created and managed the onsite charity auction, booked in
          and co-ordinated VIP guest attendees alongside entertainement aspects for the event. WE organised press
          launches in London and Zurich working with the sponsorship parter luxury men's fashion brand Hackett and added
          in guest appearances by Swiss pop band Pegasus and British society star Tara Palmer Tomkinson. ​​​​​
        </p>
        <Spacer size={16} />
        <StyledImageWrapper
          src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-altitude.png"
          width="50%"
          border
        />
        <Spacer size={16} />
        <p>
          Altitude Festival in Klosters - a sister event to the Klosters Snow Polo event that Adams&Neill co-ordinate
          talent booking ( pop acts Duran Duran and Pegasus in 2012) brand concept and marketing campaign for.{" "}
        </p>
      </div>
    );
}