export const reducer = (array, string) => {
  let newArray = [];
  array.map((elem) => {
    if (elem["node"][string]) newArray.push(elem["node"][string]);
  });
  return newArray;
};

export const setObject = (data, keys, array) => {
  //create blank arrays
  const returnedArray = []
  const arrayOfArrays = []
  array.map((elem) => {
    arrayOfArrays.push(reducer(data, elem))
  })
  for(let i=0; i < arrayOfArrays.length; i++) {
    let obj = {}
    keys.map((key, index) => {
      obj[key] = arrayOfArrays[index][i]
    })
    returnedArray.push(obj)
  }
  return returnedArray
}


