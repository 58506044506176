import React from "react";
import { CarouselComponent } from "./carousel";
import { PageHeader } from "./pageHeader";
import { ContentWrapper } from "./shared";

const images = [
  "https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-content-1.png",
  "https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-content-2.png",
];

export const Content = () => (
  <div>
    <PageHeader title="Content Production" />
    <ContentWrapper>
      <p>
        Celeste Neill is a lifestyle feature writer and broadcast journaist with an extensive remit of published
        articles and radio reporting in a number of international and national media outlets. Specialising in business
        innovation and entrepreneurship, travel trends and the cooler side of culture.
      </p>
      <CarouselComponent images={images} />
      {/* <StyledImageWrapper src="http://photos.prnewswire.com/prnfull/20091204/CSLOGO?p=publish" width="200px" border />
      <Spacer size={16} />
      <a href="./directory/yourfile.pdf" download="youth-2011">
        <StyledImageWrapper
          src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-credit-suisse.png"
          width="30%"
        />
      </a>
      <Spacer size={16} />
      <StyledImageWrapper
        src="https://www.responsesource.com/content/uploads/2017/07/easyJet-Traveller.jpg"
        width="200px"
        border
      />
      <Spacer size={16} />

      <StyledImageWrapper src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-easyjet.png" width="30%" />
      <Spacer size={16} />

      <StyledImageWrapper src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-easyjet-2.png" width="30%" /> */}
    </ContentWrapper>
  </div>
);
