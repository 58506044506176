import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Divider, Grid } from "semantic-ui-react";
import { Spacer, TwoColumnLayout } from "ui-start";
import { PageHeader } from "./pageHeader";
import { UnderlinedHeader, BoldHeader } from "./shared";

export const AboutPage = () => {
  const [pageData, setPageData] = useState([]);
  const [title, setTitle] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const [servicesHeader, setServicesHeader] = useState("");
  const [servicesSectionHeader01, setServicesSectionHeader01] = useState("");
  const [servicesSectionHeader02, setServicesSectionHeader02] = useState("");
  const [servicesSectionHeader03, setServicesSectionHeader03] = useState("");
  const data = useStaticQuery(graphql`
    query MyQuery {
      allGoogleSheetAboutRow {
        edges {
          node {
            servicesection01Copy
            servicesection01Header
            servicesection02Copy
            servicesection02Header
            servicesection03Copy
            servicesection03Header
            servicesectionheader
            title
            heroimage
            copy
          }
        }
      }
    }
  `);
  useEffect(() => {
    const dataArray = data.allGoogleSheetAboutRow.edges;
    setPageData(dataArray);
    setTitle(dataArray[0]["node"]["title"]);
    setHeroImage(dataArray[0]["node"]["heroimage"]);
    setServicesHeader(dataArray[0]["node"]["servicesectionheader"]);
    setServicesSectionHeader01(dataArray[0]["node"]["servicesection01Header"]);
    setServicesSectionHeader02(dataArray[0]["node"]["servicesection02Header"]);
    setServicesSectionHeader03(dataArray[0]["node"]["servicesection03Header"]);
  }, [data]);

  const pageContent = (
    <>
      <PageHeader heroimage={heroImage} imageSize="small" />
      {pageData.map(({ node: { copy } }) => {
        if (copy) return <p dangerouslySetInnerHTML={{ __html: copy }} />;
      })}
      <BoldHeader>{servicesHeader}</BoldHeader>
      <br></br>
      <Grid>
        <Grid.Column mobile={16} computer={5}>
          <UnderlinedHeader>{servicesSectionHeader01}</UnderlinedHeader>
          {pageData.map(({ node: { servicesection01Copy } }) => {
            if (servicesection01Copy)
              return (
                <p dangerouslySetInnerHTML={{ __html: servicesection01Copy }} />
              );
          })}
        </Grid.Column>
        <Grid.Column mobile={16} computer={5}>
          <UnderlinedHeader>{servicesSectionHeader02}</UnderlinedHeader>
          {pageData.map(({ node: { servicesection02Copy } }) => {
            if (servicesection02Copy)
              return (
                <p dangerouslySetInnerHTML={{ __html: servicesection02Copy }} />
              );
          })}
          <Divider hidden />
        </Grid.Column>
        <Grid.Column mobile={16} computer={5}>
          <UnderlinedHeader>{servicesSectionHeader03}</UnderlinedHeader>
          {pageData.map(({ node: { servicesection03Copy } }) => {
            if (servicesection03Copy)
              return (
                <p dangerouslySetInnerHTML={{ __html: servicesection03Copy }} />
              );
          })}
        </Grid.Column>
      </Grid>
    </>
  );

  return (
    <>
      <Spacer height={24} />
      <TwoColumnLayout
        leftFlexBasis={20}
        rightFlexBasis={80}
        title={title}
        headerFontSize={30}
        mobileHeaderFontSize={35}
        titleLetterSpacing={2}
      >
        <Spacer height={6} />
        {pageContent}
      </TwoColumnLayout>
    </>
  );
};
