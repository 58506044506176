import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "@reach/router";
import { Breadcrumb } from "semantic-ui-react";
import {
  Container,
  Grid,
  Spacer,
  TwoColumnLayout,
  UIHeader,
  UIImage,
} from "ui-start";
import { reducer, setObject } from "../utilities/getSingleItemArray";
import { MenuCard } from "./MenuCard";

enum Sections {
  production = "Production",
  communications = "Communication",
  contentCreation = "Content Creation",
}

export const Highlights = () => {
  const data = useStaticQuery(graphql`
    query HighlightsQuery {
      allGoogleSheetHighlightsRow {
        edges {
          node {
            title
            section
            sectionurl
            anchor
            productiontitle
            productionurl
            productioncopy
            communicationstitle
            communicationsurl
            communicationscopy
            contenttitle
            contenturl
            contentcopy
          }
        }
      }
    }
  `);
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [highlightSections, setHighlightSections] = useState([]);
  const [showSections, setShowSections] = useState(true);
  const [sectionRendered, setSectionRendered] = useState(null);
  useEffect(() => {
    const dataArray = data?.allGoogleSheetHighlightsRow?.edges;
    const sections = reducer(dataArray, "section");
    const sectionImages = reducer(dataArray, "sectionurl");
    const anchors = reducer(dataArray, "anchor");
    const sectionArray = sections.map((sect, i) => {
      let obj: any = {};
      obj.section = sect;
      obj.sectionUrl = sectionImages[i];
      obj.anchor = anchors[i];
      return obj;
    });
    setHighlightSections(sectionArray);
    setTitle(dataArray[0]["node"]["title"]);
    setShowSections(true);
  }, []);

  useEffect(() => {
    if (!!location?.hash) {
      setSectionRendered(
        sectionsToShow.find((x) => x.slug === location.hash).content
      );
      setShowSections(false);
    } else {
      setShowSections(true);
    }
  }, [location]);

  useEffect(() => {
    console.log(isMobile)
  }, [isMobile])

  const ContentLayout = ({ title, data, sections }) => {
    const dataArray = data?.allGoogleSheetHighlightsRow?.edges;
    const titleArray = reducer(dataArray, sections[0])
    const imageArray = reducer(dataArray, sections[1])
    const copyArray = reducer(dataArray, sections[2])
    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Section>
            <Link to="/">Home</Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section>
            <Link to="/highlights">Highlights</Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>{title}</Breadcrumb.Section>
        </Breadcrumb>
        <Spacer height={32} />
        {titleArray.map((title, i) => (
          <Container key={title} width="600px" mobileWidth="100%">
            <UIHeader level={2} fontWeight={600} letterSpacing={1}>{title}</UIHeader>
            <UIImage url={imageArray[i]} alt={title} width="100%" />
            <p dangerouslySetInnerHTML={{ __html: copyArray[i] }} />
            <Spacer height={32} />
          </Container>
        ))}
      </Container>
    );
  };

  const sectionsToShow = [
    {
      slug: "#production",
      content: (
        <ContentLayout
          title="Production"
          data={data}
          sections={["productiontitle", "productionurl", "productioncopy"]}
        />
      ),
    },
    {
      slug: "#communications",
      content: (
        <ContentLayout
          title="Communications"
          data={data}
          sections={[
            "communicationstitle",
            "communicationsurl",
            "communicationscopy",
          ]}
        />
      ),
    },
    {
      slug: "#content-creation",
      content: (
        <ContentLayout
          title="Content Creation"
          data={data}
          sections={["contenttitle", "contenturl", "contentcopy"]}
        />
      ),
    },
  ];

  return (
    <>
      <Spacer height={24} />
      <TwoColumnLayout
        leftFlexBasis={20}
        rightFlexBasis={80}
        title={title}
        headerFontSize={30}
        mobileHeaderFontSize={35}
        titleLetterSpacing={2}
      >
        <Spacer height={6} />
        {showSections ? (
          <>
            <Grid columns={3} mobileColumns={1} wrap>
              {highlightSections?.map(({ section, sectionUrl, anchor }) => (
                <Link to={`#${anchor}`}>
                  <Container padding={8}>
                    <MenuCard
                      title={section}
                      image={sectionUrl}
                      key={sectionUrl}
                    />
                  </Container>
                </Link>
              ))}
            </Grid>
          </>
        ) : (
          sectionRendered
        )}
      </TwoColumnLayout>
    </>
  );
};
