import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { ContactForm } from "../components/contact";
import { AboutPage } from "../components/about";
import { CommunicationsPage } from "../components/Communications";
import { ServicesPage } from "../components/services";
import { Events } from "../components/events";
import { Content } from "../components/content";
import { Highlights } from "../components/Highlights";

const bespokeContent = [
  {
    slug: "contact",
    component: <ContactForm />,
  },
  {
    slug: "the-agency",
    component: <AboutPage />,
  },
  {
    slug: "services",
    component: <ServicesPage />,
  },
  {
    slug: "highlights",
    component: <Highlights />,
  },
  {
    slug: "work/communications-strategy",
    component: <CommunicationsPage />,
  },
  {
    slug: "work/event-production",
    component: <Events />,
  },
  {
    slug: "work/content-production",
    component: <Content />,
  },
];

export default function BlogPost({ data }) {
  const { title, slug } = data.googleSheetMenuPagesRow;
  const bespoke = bespokeContent.find((x) => x.slug === slug)?.component;

  const TemplateContent = () => (
    <div>
      <h1>{title}</h1>
    </div>
  );

  return <Layout>{bespoke ? bespoke : <TemplateContent />}</Layout>;
}
export const query = graphql`
  query($slug: String!) {
    googleSheetMenuPagesRow(slug: { eq: $slug }) {
      slug
      title
    }
  }
`;
