import React from "react";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import { device } from "../utilities/media_sizes";
import { StyledImageCaption } from "./shared";

export const StyledImage = styled(Image)`
  width: 100% !important;
  margin: auto;

  @media ${device.tablet} {
    width: 80% !important;
  }
`;

export const PageHeader = ({
  title,
  heroimage,
  heroimagecaption,
  imageSize,
}) => {
  return (
    <div>
      <h1>{title}</h1>
      <StyledImage src={heroimage} size={imageSize} />
      <StyledImageCaption>{heroimagecaption}</StyledImageCaption>
    </div>
  );
};
