export const validateRequiredFields = (values) => {
    let errorMessages = []
    values.forEach((x) => {
        if(x.required && x.value.length < 1) errorMessages.push(`*${toTitleCase(x.id)} is a required field`) 
    })
    return errorMessages
}

export const checkEmailisValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) return "*Email is not valid"
}

const toTitleCase = (string) => {
    return string.split("").map((x,i) => i === 0 ? x.toUpperCase() : x).join("");
}