import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const CarouselComponent = ({ images }) => (
  <Carousel autoPlay showArrows={true} showThumbs={false} infiniteLoop={true} showStatus={false}>
    {images.map((image, i) => (
      <div key={i}>
        <img src={image} alt="Carousel" />
      </div>
    ))}
  </Carousel>
);
