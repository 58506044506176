import React from "react";
import { PageHeader } from "./pageHeader";
import { BoldHeader, StyledImageWrapper } from "./shared";
import { Spacer } from "./styles";

export const CommunicationsPage = () => (
         <div>
           <PageHeader title="Communications Strategy" />
           <p>
             Adams & Neill have over twenty years experience in public relations, marketing and creative consultancy.
             Having overseen campaigns from Edinburgh, London, Geneva and Zurich the team have a specialised knowledge
             of international media. We deliver content that builds brands, that engages their audience whilst reaching
             out to new audiences. We believe in building personal relationships between the brand and key media. We've
             delivered PR campaigns that have reached audiences as wide and as diverse as national Chinese television to
             blogs for local mothers. We believe in the niche community as much as the mass circulation, which in
             today's media are equally important for brand buidling.
           </p>
           <BoldHeader>Some previous clients:</BoldHeader>
           <StyledImageWrapper
             src="https://1000logos.net/wp-content/uploads/2020/04/Karen-Millen-logo.jpg"
             width="200px"
             border
           />
           <Spacer size={16} />
           <StyledImageWrapper src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-comms-1.png" width="50%" />
           <Spacer size={16} />
           <p>
             British fashion brand Karen Millen offers accessible luxury and attainable design whilst maintaining the
             brands instantly recognisable effortless glamour, signature fit and attention to detail. Karen Millen is
             the go-to destination for stylish investment pieces around the world, whether it is for immaculately
             tailored work-wear, a distinctive dress or a sumptuous leather jacket.
           </p>
           <Spacer size={16} />
           <StyledImageWrapper
             src="https://virtuagym.b-cdn.net/wp-content/uploads/2020/12/Holmes-Place-logo.png"
             width="200px"
             border
           />
           <Spacer size={16} />
           <StyledImageWrapper src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-comms-2.png" width="50%" />
           <Spacer size={16} />
           <p>
             With 300,000 members at more than 75 locations worldwide, Holmes Place Health Clubs is one of the leading
             providers of comprehensive health and fitness support, true to their motto: one life. live it well. Their
             mission is to get members to make a commitment to a healthy lifestyle and invest in the brands philosophy
             of moving well, eating well, and feeling well. www.holmesplace.ch The company have three clubs in
             Switzerland: Geneva, Lausanne, Zurich ( with spa facilities) and Oberrieden - Seepark.
           </p>
           <Spacer size={16} />
           <StyledImageWrapper src="https://www.purobeach.com/static/img/logo_puro.png" width="200px" border />
           <Spacer size={16} />
           <StyledImageWrapper src="https://adamsniell.s3.us-east-2.amazonaws.com/adam-neill-comms-3.png" width="50%" />
           <p>
             Purobeach is part of Puro Group, operating five beach locations – Porto Montenegro, Palma Bay, Marbella,
             Black Sea and Vilamoura, as well as Puro Hotel Palma. Purobeach offers the ultimate relaxed hang-out by the
             sea, with inviting day beds and sofas; international menus; spectacular sunsets and DJ selected music that
             takes guests from sunrise to sunset as well as sea- side spa treatments and daily yoga. Puro Hotel is a
             restored 18th century town palace, an oasis where urban contemporary luxury meets Spanish history. A hotel
             for global nomads looking for a casual atmosphere that embraces today‘s luxuries, rather than the trappings
             of traditional prestige. Purobeach, “Oasis del Mar”, continues to expand its portfolio of locations, now in
             the beautiful setting of Marina di Scarlino in Tuscany. The beach club lifestyle concept will also include
             32 fully equipped suites within walking distance of Purobeach. www.purobeach.com
           </p>
         </div>
       );
