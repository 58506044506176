import React, { useState } from "react";
import styled from "styled-components";
import { Button, Form } from "semantic-ui-react";
import { checkEmailisValid, validateRequiredFields } from "../utilities/validation";

const INITIAL_STATE = [
  {
    id: "name",
    value: "",
    required: true,
  },
  {
    id: "email",
    value: "",
    required: true,
  },
  {
    id: "message",
    value: "",
    required: true,
  },
];

const ErrorMessage = styled.p`
  color: red;
`;

const StyledButton = styled(Button)`
  background-color: #330000 !important;
  color: white !important;
`;

export const ContactForm = () => {
  const [values, setValue] = useState(INITIAL_STATE);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleInput = (e) => {
    const { id, value } = e.target;
    const inputValue = {
      id,
      value,
    };
    const inputArray = values.map((x) => (x.id === inputValue.id ? inputValue : x));
    setValue(inputArray);
  };

  const handleSubmit = (e) => {
    let messages = validateRequiredFields(values);
    const emailValue = values.find((x) => x.id === "email").value
    if(!!emailValue) {
      messages = [...messages, checkEmailisValid(emailValue)];
    }
    if(messages.length > 0) {
       e.preventDefault();
       setErrorMessages(messages)
    }
  };

  return (
    <>
      <h1>Get in touch...</h1>
      <Form action="https://submit-form.com/BDcU2pee" target="_self">
        <Form.Field>
          <label>Name*</label>
          <input placeholder="Name" onChange={handleInput} id="name" name="name" />
        </Form.Field>
        <Form.Field>
          <label>Email*</label>
          <input placeholder="Email" onChange={handleInput} id="email" name="email" />
        </Form.Field>
        <Form.TextArea label="Message*" placeholder="Your message" onChange={handleInput} id="message" name="message" />
        {errorMessages?.map((message, i) => (
          <ErrorMessage key={i}>{message}</ErrorMessage>
        ))}
        <StyledButton type="submit" onClick={handleSubmit}>
          Submit
        </StyledButton>
      </Form>
    </>
  );
};
